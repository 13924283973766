"use client";

import React, { useEffect, useState } from "react";
import { TimeZone, timezones } from "../utils/timezones";
import mixpanel from "mixpanel-browser";
import { getSlugAndRouteName } from "../utils/Functions";
import { useParams } from "next/navigation";
import moment from "moment-timezone";
export const AppContext = React.createContext({});
const metadata = {
  title: "Become Top 1% in the AI-First World",
  description: "Whether it is Product, Growth, Design, Management, Tech & Data, Business, GrowthSchool is the place to learn from top experts in the field."
};
export const AppContextProvider = (props: any) => {
  const [isBeginner, setIsBeginner] = useState(false);
  const [isIntermediate, setIntermediate] = useState(false);
  const [isAdvanced, setisAdvanced] = useState(false);
  const [ogImage, setogImage] = useState("https://assets-global.website-files.com/624194472db3153002097068/624ace96c9f9ab0aed097ba6_GS%20Logo%20(5)%201.svg");
  const [title, settitle] = useState(metadata.title);
  const [description, setdescription] = useState(metadata.description);
  const [isAll, setIsAll] = useState<{
    checked: boolean;
    state: boolean;
  }>({
    checked: false,
    state: true
  });
  const [breadcrumbs, setBreadCrumbs] = useState<{
    industryName: string;
    programName: string;
  }>({
    industryName: "Marketing",
    programName: ""
  });
  const [pixelLoaded, setPixelLoaded] = useState(false);
  const temp = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name: "Become Top 1% in the AI-First World",
    description: "Whether it is Product, Growth, Design, Business, Tech or Data, GrowthSchool is the place to learn from top experts in the field to become the Top 1%",
    provider: {
      "@type": "Organization",
      name: "GrowthSchool"
    }
  };
  const [schemaData, setSchemaData] = useState(temp);
  const [industry, setindustry] = useState("");
  const [course, setCourse] = useState("");
  const [showBreadCrumbModal, setshowBreadCrumbModal] = useState(false);
  const [showBreadCrumbDesktopModal, setshowBreadCrumbDesktopModal] = useState(false);
  const [showIndustryContent, setshowIndustryContent] = useState(true);
  const [mixpanelInstance, setMixpanelInstance] = useState<any>(null);
  const [article, setArticle] = useState("");
  const [topic, setTopic] = useState("");
  const [subTopic, setSubTopic] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("Marketing Pro");
  const params = useParams();
  const {
    isIndustry,
    isSubIndustry
  } = getSlugAndRouteName(params?.route?.toString() || '');
  const setIndustry = (industry: string) => {
    setindustry(industry);
  };
  const [selectedTimeZone, setSelectedTimeZone] = useState<TimeZone>(timezones.filter((item: any) => item.abbr === moment().tz(moment.tz.guess()).format("z"))[0]);
  useEffect(() => {
    if (isIndustry || isSubIndustry) {
      window.scrollTo(0, 0);
    }
    if (isBeginner && isIntermediate && isAdvanced) {
      setIsBeginner(false);
      setIntermediate(false);
      setisAdvanced(false);
      setIsAll({
        checked: true,
        state: true
      });
      return;
    }
    if (isBeginner || isIntermediate || isAdvanced) {
      setIsAll({
        checked: false,
        state: false
      });
    }
    if (!isBeginner && !isIntermediate && !isAdvanced && !isAll.state) {
      setIsAll({
        checked: false,
        state: true
      });
    }
  }, [isBeginner, isIntermediate, isAdvanced]);
  useEffect(() => {
    if (isIndustry || isSubIndustry) {
      window.scrollTo(0, 0);
    }
    if (isAll.state) {
      setIsBeginner(false);
      setIntermediate(false);
      setisAdvanced(false);
    }
  }, [isAll]);
  const updateSchemaData = (data: any) => {
    setSchemaData(prevData => ({
      ...prevData,
      ...data
    }));
  };
  useEffect(() => {
    let mixpanelInstance: any = null;
    const initializeMixpanel = () => {
      if (process.env.NEXT_PUBLIC_APP_MIXPANEL_TOKEN && typeof window !== 'undefined') {
        try {
          mixpanel.init(process.env.NEXT_PUBLIC_APP_MIXPANEL_TOKEN, {
            debug: process.env.NODE_ENV === 'development'
          });
          mixpanel.register({
            program_type: "gs-website"
          });
          mixpanelInstance = mixpanel;
          setMixpanelInstance(mixpanelInstance);
        } catch (error) {
          console.error('Mixpanel initialization error:', error);
        }
      }
    };
    initializeMixpanel();
    return () => {
      if (mixpanelInstance) {
        mixpanelInstance.reset();
      }
    };
  }, []);
  return <AppContext.Provider value={{
    isBeginner,
    setIsBeginner,
    isIntermediate,
    setIntermediate,
    isAdvanced,
    setisAdvanced,
    isAll,
    setIsAll,
    industry,
    setIndustry,
    course,
    setCourse,
    showBreadCrumbModal,
    setshowBreadCrumbModal,
    showBreadCrumbDesktopModal,
    setshowBreadCrumbDesktopModal,
    selectedTimeZone,
    setSelectedTimeZone,
    showIndustryContent,
    setshowIndustryContent,
    schemaData,
    setSchemaData,
    updateSchemaData,
    mixpanelInstance,
    article,
    setArticle,
    topic,
    setTopic,
    subTopic,
    setSubTopic,
    ogImage,
    setogImage,
    title,
    settitle,
    description,
    setdescription,
    breadcrumbs,
    setBreadCrumbs,
    pixelLoaded,
    setPixelLoaded,
    selectedPlan,
    setSelectedPlan
  }} data-sentry-element="unknown" data-sentry-component="AppContextProvider" data-sentry-source-file="appcontext.tsx">
      {props.children}
    </AppContext.Provider>;
};